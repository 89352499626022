import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { throttle } from "lodash";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  Invoices:any;
  previewInvoice:any;
  totalPage:number;
  itemsPerPage:number;
  currentInvoices:any;
  currentPage:number;
  isLoading:boolean;
  pdfBlob:any;
  sortDirection:any;
  openModel:string;
  filterInvoices: string;
  numPages: any,
  pageNumber: number,
  isSuspended : boolean;
  suspendedMessage : string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  getInvoiceCallId:string| Message="";
  userSuspendedApiCallId : string  =  "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      invoice: "",
      invoiceError: "",
      invoicePdf: "",
      Invoices:"",
      totalPage:0,
      itemsPerPage:12,
      currentInvoices:'',
      currentPage:1,
      isLoading:true,
      sortDirection:"",
      pdfBlob:"",
      openModel:"",
      previewInvoice:{},
      numPages: null,
      pageNumber: 1,
      filterInvoices:"day",
      isSuspended : false,
      suspendedMessage : "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiCallId === this.getInvoiceCallId) {
        this.setState({isLoading:false})
        if (responseJson) {
        this.setState({ Invoices: responseJson.invoices.Invoices })
        }
        this.setState({
          totalPage : this.state?.Invoices?.length/this.state.itemsPerPage
        })
      }else if(apiCallId === this.pdfApiCallId){
        if(responseJson){
          this.setState({previewInvoice:responseJson.url,openModel:"Preview"})
        }
      }else if(apiCallId === this.userSuspendedApiCallId) {
        this.handleIsUserSuspended(message)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount()
     this.setState({isLoading:true})
     this.getInvoices();
     this.setState({sortDirection:"asc"})
     this.handleProtectedRouting()
     window.addEventListener("mousemove", this.checkIsUserIsSuspended)
     
  }


  handleProtectedRouting = async () => {
    let role = await getStorageData("role")
    if (role === "ClientUser") {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'PageNotFound');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  checkIsUserIsSuspended = throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },4000)

  handleIsUserSuspended = (message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(responseJson.status && responseJson.status === "suspended"){
      this.setState({isSuspended:true,suspendedMessage:responseJson.message})
      window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    this.setState({isSuspended:false,suspendedMessage:""})
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  onDocumentLoadSuccess = ( numPages:any ) => {
    this.setState({ numPages:numPages.numPages});
  }
  handleSortClick = () => {
    const { sortDirection, Invoices } = this.state;
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    Invoices.sort((a:any, b:any) => {
      const dateA = new Date(a.DueDateString).getTime();
      const dateB = new Date(b.DueDateString).getTime();

      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });

    this.setState({ sortDirection: newSortDirection, Invoices: Invoices });
  };

  
  getInvoices = async() => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInvoicesEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  previewInvoices = async(id:any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pdfApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/download_invoice_pdf?invoice_uid=${id}&preview=true`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setFilterTxt = (textLabel: string) =>{
    this.setState({filterInvoices: textLabel})
    this.getFilteredInvoices(textLabel)
  }

  onCancel =()=>{
    this.setState({openModel:""})
  }

  getFilteredInvoices = async(filters:any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/get_invoices?filter_by=${filters}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

showPdfPreview = (pdfBlob:any) => {
   this.setState({pdfBlob:pdfBlob,openModel:"preview"})
  };

fetchData = (id:any) => {
  const apiUrl = `${baseURL}/download_invoice_pdf?invoice_uid=${id}&preview=false`;

  const authToken = localStorage.getItem('authToken');

  const fetchOptions = {
    method: "GET",
    headers: {
      "token": `${authToken}`,
    },
  };
  fetch(apiUrl, fetchOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then(blob => {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "invoice.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      this.showPdfPreview(blob);
    })
    .catch(error => {
      console.error("Error fetching the PDF:", error);
    });
};

showPDF = (id:any) => {
  const apiUrl = `${baseURL}/download_invoice_pdf?invoice_uid=${id}`;

  const authToken = localStorage.getItem('authToken');

  const fetchOptions = {
    method: "GET",
    headers: {
      "token": `${authToken}`,
    },
  };
  fetch(apiUrl, fetchOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then(blob => {
      this.showPdfPreview(blob);
    })
    .catch(error => {
      console.error("Error fetching the PDF:", error);
    });
};

handlePageChange = (newPage: any) => {
  const { Invoices } = this.state;
  const startIndex = (newPage-1)*this.state.itemsPerPage;
  const endIndex = startIndex+this.state.itemsPerPage;
  this.setState({
    currentInvoices :Invoices.slice(startIndex,endIndex),
    currentPage : newPage
  }) ;
}

  homeNavigation = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  enquiryNavigation = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Enquiries");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  profileNavigation() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableuserProfiles");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
 
  helpCenterNavigation() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "HelpCenter");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  settingsNavigation(){
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
    quoteNaviagtion = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
   

  ////////////////////////////////////

 onChangeInvoice = (text: string) => {
    if (text === "") {
      this.setState({
        invoice: text,
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ invoice: text, invoiceError: "" });
    }

  };

  getPdfLink = () => {
    if (this.state.invoice === "" || this.state.invoice.length !== 6) {
      this.setState({
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.pdfApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.exampleAPiEndPoint + this.state.invoice
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  // Customizable Area End
}
